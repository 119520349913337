<template>
   <div id="inside">
     
    <div class="container">
      <div class="row">
        <div class="">
        </div>
        <div class="btn-group btn-group-sm">
            <a
              href="/"
              class="btn btn-link d-flex flex-column justify-content-center text-secondary"
              ><i class="fa fa-home fa-fw fa-lg"></i
            ></a>
            <router-link
              to="/dashboard/meusBoletos"
              ><a class="btn btn-link text-secondary">Boletos Ativos</a>
            </router-link>
            <router-link
              to="#"
              ><a href="#" class="btn btn-link text-secondary" style="text-decoration: none; cursor: initial;">Acordo Fechado</a>
            </router-link>
        </div>
        <div class="col-md-12">
            <h1>Acordo fechado</h1>
            <p class="text-muted">Pague os boletos abaixo até as datas de vencimento.</p>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <p class="text-info mb-0">Documento {{ formatDocument(user.DocNumber) }}<br></p>
          <p class="text-info">
            {{ formatData(responseFromAgreement.XML.PRESTACOES.PREST.DATVENCI, "YYYY-MM-DD", "DD/MM/YYYY") }}
            <br>
          </p>
        </div>
        <div class="d-inline-flex flex-wrap flex-column col-acao col-lg-6">
          <div class="card">
            <div class="card-header">
              <i class="fa fa-fw mr-2 fa-circle text-warning"></i>
              Boleto ativo
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 col-12">
                  <h6 class="">Contrato(s) {{ formatedContracts(responseFromAgreement.XML.DADOBOLETO.CONTRATOS) }}</h6>
                  <p>
                    {{ responseFromAgreement.XML.DADOBOLETO.COMPOSICAO1 + " " + responseFromAgreement.XML.DADOBOLETO.COMPOSICAO2 }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6 col-6">
                      <p class="m-0 text-info">Valor:</p>
                      <h6>{{ formattedValue(responseFromAgreement.XML.VALORPRINCIPAL) }}</h6>
                    </div>
                    <div class="col-md-6 col-6">
                      <p class="m-0 text-info">Vencimento:</p>
                      <h6>{{ formatData(responseFromAgreement.XML.PRESTACOES.PREST.DATVENCI, "YYYY-MM-DD", "DD/MM/YYYY") }}</h6>
                    </div>
                  </div>
                  <hr>
                  <p class="text-info mb-1">Linha digitável:</p>
                  <p class="codebar" id="linhaDigitavel">{{ responseFromAgreement.XML.DADOBOLETO.LINHADIGITAVEL }}</p>
                  <a class="btn mr-1 mb-1 btn-outline-primary btn-block" href="#" @click="copiarLinhaDigitável()"><i class="fa fa-copy fa-fw"
                    @click="copiarLinhaDigitável()"></i>&nbsp;Copiar linha digitável</a>
                  <a class="btn mr-1 mb-1 btn-outline-primary btn-block" href="#"><i class="fa fa-download fa-fw"></i>&nbsp;Baixar boleto</a>
                  <a class="btn mr-1 mb-1 btn-outline-primary btn-block" href="#" @click="sendSms()"><i class="fa fa-fw fa-mobile"></i>&nbsp;Receber por SMS</a>
                  <a class="btn mr-1 mb-1 btn-outline-primary btn-block" href="#" @click="sendEmail()"><i class="fa fa-fw fa-envelope-o"></i>&nbsp;Receber por e-mail</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { formatCurrencieValueBRL as formatValue, formatData, formatDocument } from "@/utils/helpers";
// import { bradescoTemplate } from "../../../utils/templates";
// import { render } from 'mustache'

export default {
    name: "HistoricoPagamentos",
     components: {
    },
    data () {
        return {
            loadDeal: false,
            // agreement: null,
            contract :null,
            selectDeal:null,
            digitalLine:null,
            contractFull: null,
            checkSelectAll: false,
            checkContract: false,
            links: [],
            responseFromAgreement: {},
        }
    },
    methods: {
        formatValue,
        formatData,
        formatDocument,

        ...mapActions('minhasDividas', [
          'ActionDividaNegociar',
          'ActionSendEmailOrSmsText'
        ]),

        formatedContracts(contracts) {
          const allContracts = contracts.split(';')
          return allContracts.length > 2 ? String(allContracts) : allContracts[0]
        },

        formattedValue(value) {
          return parseFloat(value.replace(',','.'), 10).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        },

        // exibirHTML() {
        //   render(bradescoTemplate)
        // },


        async sendSms() {
          this.loading = true;

            let response = await this.ActionSendEmailOrSmsText({
              LinhaDigitavel: this.responseFromAgreement.XML.DADOBOLETO.LINHADIGITAVEL,
              Email: '',
              PhoneNumber: this.user.MobileNumber
            });
            this.loading = false;

            if(!response.data.error){
              this.flashMessage.show({
                timeout:1000,
                blockClass: 'custom-block-class',
                status: 'success', 
                title: 'Sucesso', 
                message: 'Linha digitável enviado por sms com sucesso'});
            }else{
                this.flashMessage.show({
                  timeout:1000,
                  status: 'error', 
                  blockClass: 'custom-block-class',
                  title: 'Erro', 
                  message: 'Ocorreu um erro ao tentar enviar o SMS, tente novamente mais tarde'});
            }
          
        },

        async sendEmail() {
          this.loading = true;

            let response = await this.ActionSendEmailOrSmsText({
              LinhaDigitavel: this.responseFromAgreement.XML.DADOBOLETO.LINHADIGITAVEL,
              Email: this.user.Email,
              PhoneNumber: ''
            });
            this.loading = false;

            if(!response.data.error){
              this.flashMessage.show({
                timeout:1000,
                blockClass: 'custom-block-class',
                status: 'success', 
                title: 'Sucesso', 
                message: 'Linha digitável enviado por e-mail com sucesso'});
            }else{
                this.flashMessage.show({
                  timeout:1000,
                  status: 'error', 
                  blockClass: 'custom-block-class',
                  title: 'Erro', 
                  message: 'Ocorreu um erro ao tentar enviar o e-mail, tente novamente mais tarde'});
            }
          
        },

        copiarLinhaDigitável(){
          const linhaDigitavel = this.responseFromAgreement.XML.DADOBOLETO.LINHADIGITAVEL
          const elem = document.createElement("textarea");
          elem.value = linhaDigitavel;
          document.body.appendChild(elem);
          elem.select();
          document.execCommand("copy");
          document.body.removeChild(elem);

          this.flashMessage.show({
            timeout: 1000,
            status: "success",
            title: "Sucesso",
            blockClass: "custom-block-class",
            message: "Linha digitável foi copiada para o seu clipboard",
          });
        },

        deactivated() {
            this.divida = null;
            this.negociacao = null;
            this.selectDeal = null;
            this.digitalLine = null;
        },
        voltar() {
             this.$router.push({ 
               name: 'negociarDivida',
               params: { contracts: this.contractFull },
              });
        },
        showDigitalLine(){
          this.digitalLine = this.selectDeal.linhaDigitavel;
        },

        selectAll() {
              var marcar = document.getElementById('all').checked;
              var lista = document.getElementsByClassName("form-check-input check1 bg-primary");
              for (var i = 0; i < lista.length; i++) {
                lista[i].checked = marcar;
              }
        },

        select() {
          var lista = document.getElementsByClassName("form-check-input check1 bg-primary");
          var all = document.getElementById('all');
          if(lista.length <= 2) {
            all.checked = false;
          }
        },

        clear() {
              var lista = document.getElementsByClassName("form-check-input check1 bg-primary");
              for (var i = 0; i < lista.length; i++) {
                lista[i].checked = false;
              }
        },
    },
    async created() {
        this.user.Menus.forEach((menuId) => {
          switch (parseInt(menuId)) {
            case 2:
              this.links.push({
                icon: "",
                text: "Contratos Negociáveis",
                route: "/dashboard/minhas-dividas",
              });
              break;
            case 1:
              this.links.push({
                icon: "",
                text: "Boletos Ativos",
                route: "meusBoletos",
              });
              break;
            case 3:
              this.links.push({
                icon: "",
                text: "Acordos",
                route: "/dashboard/meus-acordos",
              });
              break;
          }
        });
        this.loadDeal = true;
        this.responseFromAgreement = this.$route.params.responseFromAgreement;
        this.loadDeal = false;

    },
    computed: {
      ...mapState("auth", ["user"]),
      isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
    }
};
</script>
